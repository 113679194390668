<template>
  <div class="vx-col w-full">
    <div class="vx-row" v-if="status_datastaff === true">
      <div class="vx-col w-full">
        <vx-card title="เลือกวันเวลา">
          <label>เริ่มต้น: </label>
          <flat-pickr
            :config="configdateTimePicker"
            v-model="before_datetime"
            placeholder="Date Time"
          />
          <br />
          <br />
          <label> สิ้นสุด: </label>
          <flat-pickr
            :config="configdateTimePicker"
            v-model="after_datetime"
            placeholder="Date Time"
          />
          <br />
          <br />
          <vs-button
            color="primary"
            @click="
              check_user_datelimit(before_datetime, after_datetime, 'fulltime')
            "
            >ค้นหา</vs-button
          >
        </vx-card>
      </div>
    </div>
    <div class="vx-row" v-if="status_datastaff === false">
      <div class="vx-col w-full">
        <vx-card title="เลือกวันเวลา">
          <label>เริ่มต้น: </label>
          <flat-pickr
            :config="configdateTimePicker48hr"
            v-model="before_datetime48hr"
            placeholder="Date Time"
          />
          <br />
          <br />
          <label> สิ้นสุด: </label>
          <flat-pickr
            :config="configdateTimePicker48hr"
            v-model="after_datetime48hr"
            placeholder="Date Time"
          />
          <br />
          <br />
          <vs-button
            color="primary"
            @click="
              check_user_datelimit(
                before_datetime48hr,
                after_datetime48hr,
                '48hr'
              )
            "
            >ค้นหา</vs-button
          >
        </vx-card>
      </div>
    </div>
    <br />
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
        @click="
          $store.state.AppActiveUser.permissions.report.action
            ? (activePrompt1 = true)
            : null
        "
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.vip"
          statisticTitle="รวมเปิดยูสเติมเงิน"
          color="success"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
        @click="
          $store.state.AppActiveUser.permissions.report.action
            ? (activePrompt2 = true)
            : null
        "
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UserCheckIcon"
          icon-right
          :statistic="sum_data.register_vip"
          statisticTitle="สมัครและเปิดยูส"
          color="success"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
        @click="
          $store.state.AppActiveUser.permissions.report.action
            ? (activePrompt3 = true)
            : null
        "
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.register"
          statisticTitle="สมัครสมาชิก"
          color="danger"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
        @click="
          $store.state.AppActiveUser.permissions.report.action
            ? (activePrompt4 = true)
            : null
        "
      >
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.normal"
          statisticTitle="สมัครและยังไม่เปิดยูส"
          color="warning"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.ads[0].count"
          statisticTitle="ช่องทาง อื่นๆ"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.ads[1].count"
          statisticTitle="ช่องทาง แนะนำเพื่อน"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.ads[2].count"
          statisticTitle="ช่องทาง ป้ายโฆษณา"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.ads[3].count"
          statisticTitle="ช่องทาง Facebook"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UsersIcon"
          icon-right
          :statistic="sum_data.ads[4].count"
          statisticTitle="ช่องทาง Google"
          color="success"
        />
      </div>
    </div>
    <vs-prompt
      title="รวมเปิดยูสเติมเงิน"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel(get_sumdata.data_vip, 'รวมเปิดยูสเติมเงิน')"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt1"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      /><br />
      <span>กรอกจำนวนเงินขั้นต่ำที่ต้องการโหลดข้อมูล :</span>
      <vs-input v-model="amount" type="number" class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt
      title="สมัครและเปิดยูส"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel(get_sumdata.data_regster_vip, 'สมัครและเปิดยูส')"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt2"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt
      title="สมัครสมาชิก"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel(get_sumdata.data_register, 'สมัครสมาชิก')"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt3"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt
      title="สมัครสมาชิกและไม่เปิดยูส"
      class="export-options"
      @cancle="clearFields"
      @accept="
        exportToExcel(get_sumdata.data_normal, 'สมัครสมาชิกและไม่เปิดยูส')
      "
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt4"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  data () {
    return {
      before_datetime48hr: this.moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      after_datetime48hr: moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
      configdateTimePicker48hr: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: this.moment().subtract(1, 'days').format('YYYY-MM-DD'),
        maxDate: moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
      },
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      after_datetime: moment
        .tz('Asia/Bangkok')
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      fileName: '',
      amount: '',

      headerTitle: [
        'Username',
        'วันที่สมัคร',
        'วันที่เติมเงินครั้งแรก',
        'เบอร์โทรศัพท์',
        'LINE'
      ],
      headerVal: [
        'member_username',
        'member_register_date',
        'member_vip_date',
        'member_phone',
        'member_line'
      ],
      get_sumdata: [],
      select_amount: [],
      sum_data: { vip: '', register_vip: '', register: '', normal: '' },
      activePrompt1: false,
      activePrompt2: false,
      activePrompt3: false,
      activePrompt4: false,
      formats: ['xlsx', 'csv', 'txt'],
      selectedFormat: 'xlsx',
      cellAutoWidth: true,
      datastaff: '',
      status_datastaff: ''
    }
  },
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  async mounted () {
    await axios
      .get(
        `reportregister/register/${this.before_datetime}/${this.after_datetime}`
      )
      .then((response) => (this.get_sumdata = response.data))
    this.sum_data.vip = this.get_sumdata.vip
    this.sum_data.ads = this.get_sumdata.adstype
    this.sum_data.register_vip = this.get_sumdata.register_vip
    this.sum_data.register = this.get_sumdata.register
    this.sum_data.normal = this.get_sumdata.normal
    this.fileName = `Report_Register ${this.before_datetime} ${this.after_datetime}`
    this.call_user_info()
  },
  methods: {
    async call_user_info () {
      await axios
        .get(`settingstaff/getstaff/${this.$store.state.AppActiveUser.uid}`)
        .then((response) => (this.datastaff = response.data))

      this.status_datastaff = this.datastaff[0].admin_datelimit
    },
    async check_user_datelimit (before, after, status) {
      await this.call_user_info()
      if (this.status_datastaff === false && status === 'fulltime') {
        window.location.reload()
      } else if (this.status_datastaff === true && status === 'fulltime') {
        await this.selectdate_time(before, after)
      } else if (this.status_datastaff === false && status === '48hr') {
        await this.selectdate_time(before, after)
      } else if (this.status_datastaff === true && status === '48hr') {
        window.location.reload()
      }
    },
    async selectdate_time (before, after) {
      await axios
        .get(`reportregister/register/${before}/${after}`)
        .then((response) => (this.get_sumdata = response.data))
      this.sum_data.vip = this.get_sumdata.vip
      this.sum_data.ads = this.get_sumdata.adstype
      this.sum_data.register_vip = this.get_sumdata.register_vip
      this.sum_data.register = this.get_sumdata.register
      this.sum_data.normal = this.get_sumdata.normal
      this.fileName = `Report_Register ${before} ${after}`
    },
    async exportToExcel (sum_data) {
      if (this.status_datastaff === true) {
        if (this.amount > 0) {
          await axios
            .get(
              `reportregister/getamount/${this.before_datetime}/${this.after_datetime}/${this.amount}`
            )
            .then((response) => (this.select_amount = response.data))
          sum_data = this.select_amount.data_vip
        }
        await import('@/vendor/Export2Excel').then((excel) => {
          const list = sum_data
          const data = this.formatJson(this.headerVal, list)
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
        })
      } else {
        if (this.amount > 0) {
          await axios
            .get(
              `reportregister/getamount/${this.before_datetime}/${this.after_datetime}/${this.amount}`
            )
            .then((response) => (this.select_amount = response.data))
          sum_data = this.select_amount.data_vip
        }
        await import('@/vendor/Export2Excel').then((excel) => {
          const list = sum_data
          const data = this.formatJson(this.headerVal, list)
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })

          this.clearFields()
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      })
      )
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
      this.amount = 0
    }
  }
}
</script>

<style></style>
